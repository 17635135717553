import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ZoocasaUsaIcon = ({ className, onClick }: Props) => {
  return (
    <svg width="195" height="21" viewBox="0 0 195 21" fill="none" transform='scale(1.17) translate(13)' xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3563 15.8118V18.6377H0.848022V17.8037L8.51514 6.17575H1.84404V3.34978H13.3332V4.18371L5.64286 15.8118H13.3563Z" fill="white"/>
      <path d="M34.0701 10.9938C34.0701 15.4875 30.4103 18.9156 25.9166 18.9156C21.4228 18.9156 17.8093 15.4875 17.8093 10.9938C17.8093 6.5001 21.4228 3.07186 25.9166 3.07186C30.4103 3.07186 34.0701 6.5001 34.0701 10.9938ZM21.0291 10.9938C21.0291 13.9587 23.0906 16.0202 25.9397 16.0202C28.7425 16.0202 30.8504 13.9587 30.8504 10.9938C30.8504 8.05201 28.7425 5.96727 25.9397 5.96727C23.0906 5.96727 21.0291 8.05201 21.0291 10.9938Z" fill="white"/>
      <path d="M55.8789 10.9938C55.8789 15.4875 52.2191 18.9156 47.7253 18.9156C43.2316 18.9156 39.6181 15.4875 39.6181 10.9938C39.6181 6.5001 43.2316 3.07186 47.7253 3.07186C52.2191 3.07186 55.8789 6.5001 55.8789 10.9938ZM42.8378 10.9938C42.8378 13.9587 44.8994 16.0202 47.7485 16.0202C50.5513 16.0202 52.6592 13.9587 52.6592 10.9938C52.6592 8.05201 50.5513 5.96727 47.7485 5.96727C44.8994 5.96727 42.8378 8.05201 42.8378 10.9938Z" fill="white"/>
      <path d="M75.7389 6.87072L73.3762 8.53841C72.357 6.91698 70.9209 5.96727 68.9984 5.96727C66.1492 5.96727 64.0645 8.05201 64.0645 11.017C64.0645 13.9587 66.1492 16.0202 68.9984 16.0202C70.9209 16.0202 72.357 15.0706 73.3762 13.4491L75.7389 15.1167C74.4186 17.4563 71.9401 18.9156 68.9752 18.9156C64.4351 18.9156 60.8448 15.4875 60.8448 10.9938C60.8448 6.5001 64.4351 3.07185 68.9984 3.07185C71.9401 3.07186 74.3954 4.53115 75.7389 6.87072Z" fill="white"/>
      <path d="M109.458 5.96727L107.327 7.61195C106.586 6.5001 105.659 5.85148 104.409 5.85148C103.389 5.85148 102.509 6.31471 102.509 7.33395C102.509 8.21414 103.181 8.58476 104.293 9.09442L106.03 9.85874C108.439 10.9243 109.806 12.152 109.806 14.3293C109.806 17.1784 107.489 18.9156 104.455 18.9156C101.814 18.9156 99.6832 17.5722 98.5482 15.5337L100.702 13.9124C101.536 15.0705 102.625 16.1361 104.455 16.1361C105.798 16.1361 106.632 15.5105 106.632 14.445C106.632 13.449 105.891 13.1016 104.687 12.5458L103.042 11.8045C100.865 10.8085 99.3357 9.55765 99.3357 7.40348C99.3357 4.76282 101.536 3.07187 104.409 3.07187C106.841 3.07186 108.555 4.29949 109.458 5.96727Z" fill="white"/>
      <path d="M38.4479 3.62872C38.4296 4.0409 38.2523 4.42995 37.9532 4.71421C37.6542 4.99847 37.2567 5.15583 36.8441 5.15326C36.0324 5.15326 35.2404 4.44048 35.2404 3.62872C35.2541 3.21321 35.4293 2.81944 35.7288 2.53107C36.0283 2.2427 36.4284 2.08246 36.8441 2.0844C37.0513 2.07853 37.2576 2.11403 37.451 2.18881C37.6443 2.2636 37.8208 2.37618 37.9701 2.51997C38.1194 2.66375 38.2386 2.83587 38.3206 3.02624C38.4027 3.21662 38.4459 3.42144 38.4479 3.62872Z" fill="white"/>
      <path d="M87.6788 3.164C87.4933 3.10525 87.3001 3.07421 87.1056 3.07188C86.9202 3.07257 86.736 3.10092 86.559 3.156L79.7628 18.6377H83.1214L83.9784 16.6456H90.2327L91.0897 18.6377H94.4485L87.6788 3.164ZM85.0904 14.0744L87.1056 8.93221L89.1208 14.0744L85.0904 14.0744Z" fill="white"/>
      <path d="M121.882 3.164C121.696 3.10525 121.503 3.07421 121.308 3.07188C121.123 3.07257 120.939 3.10092 120.762 3.156L113.966 18.6377H117.324L118.181 16.6456H124.435L125.292 18.6377H128.651L121.882 3.164ZM119.293 14.0744L121.308 8.93221L123.324 14.0744L119.293 14.0744Z" fill="white"/>
      <path d="M141.715 15.0992L142.716 12.3732L142.731 13.1781L140.474 7.62548H141.519L143.205 11.9133H142.898L144.508 7.62548H145.524L142.668 15.0992H141.715Z" fill="white"/>
      <path d="M137.933 12.9433C137.438 12.9433 137.023 12.8236 136.687 12.584C136.352 12.3413 136.098 12.0139 135.926 11.6019C135.753 11.1899 135.667 10.7252 135.667 10.2078C135.667 9.69034 135.752 9.22563 135.921 8.81361C136.093 8.4016 136.346 8.07742 136.678 7.84107C137.013 7.60152 137.425 7.48175 137.914 7.48175C138.399 7.48175 138.818 7.60152 139.169 7.84107C139.524 8.07742 139.797 8.4016 139.988 8.81361C140.18 9.22244 140.276 9.68715 140.276 10.2078C140.276 10.7252 140.18 11.1915 139.988 11.6067C139.8 12.0187 139.53 12.3445 139.179 12.584C138.831 12.8236 138.415 12.9433 137.933 12.9433ZM135.499 12.7996V5.90076H136.505V9.07711H136.39V12.7996H135.499ZM137.794 12.0379C138.113 12.0379 138.377 11.9564 138.585 11.7935C138.795 11.6306 138.952 11.4119 139.054 11.1372C139.159 10.8593 139.212 10.5495 139.212 10.2078C139.212 9.8692 139.159 9.56259 139.054 9.28791C138.952 9.01323 138.794 8.79445 138.58 8.63156C138.366 8.46867 138.093 8.38723 137.761 8.38723C137.448 8.38723 137.189 8.46388 136.984 8.61719C136.783 8.7705 136.633 8.98449 136.534 9.25917C136.438 9.53384 136.39 9.85004 136.39 10.2078C136.39 10.5655 136.438 10.8817 136.534 11.1564C136.63 11.431 136.782 11.6466 136.989 11.8031C137.197 11.9596 137.465 12.0379 137.794 12.0379Z" fill="white"/>
      <path d="M154.772 10.1329C154.88 11.0516 155.259 11.7647 155.909 12.2751C156.559 12.7856 157.344 13.0408 158.265 13.0408C159.077 13.0408 159.758 12.8833 160.309 12.5683C160.858 12.2547 161.341 11.8581 161.758 11.3826L164.085 13.0408C163.327 13.926 162.479 14.5545 161.541 14.9278C160.602 15.3012 159.619 15.4893 158.59 15.4893C157.615 15.4893 156.694 15.3362 155.829 15.0299C154.962 14.7237 154.213 14.2905 153.582 13.7291C152.951 13.1676 152.449 12.4924 152.079 11.702C151.709 10.9116 151.524 10.0308 151.524 9.06099C151.524 8.09121 151.708 7.21184 152.079 6.41997C152.449 5.62956 152.949 4.9529 153.582 4.39144C154.213 3.82999 154.962 3.39687 155.829 3.09062C156.696 2.78437 157.616 2.63125 158.59 2.63125C159.492 2.63125 160.317 2.78 161.066 3.0775C161.815 3.37499 162.456 3.80374 162.988 4.36519C163.52 4.92665 163.935 5.61935 164.234 6.44476C164.531 7.27017 164.681 8.21808 164.681 9.28849V10.1299H154.772V10.1329ZM161.431 7.83746C161.412 6.91872 161.115 6.2056 160.538 5.69518C159.961 5.18477 159.158 4.92957 158.129 4.92957C157.154 4.92957 156.383 5.19352 155.813 5.71998C155.246 6.24789 154.897 6.95372 154.772 7.83746H161.431Z" fill="white"/>
      <path d="M180.434 2.93924H183.519V4.77526H183.574C184.042 4.00964 184.674 3.46131 185.468 3.13028C186.262 2.79924 187.119 2.63299 188.04 2.63299C189.032 2.63299 189.925 2.79924 190.72 3.13028C191.514 3.46131 192.19 3.91777 192.751 4.49527C193.309 5.07422 193.733 5.7538 194.023 6.53546C194.31 7.31858 194.456 8.16003 194.456 9.06127C194.456 9.96252 194.303 10.8054 193.996 11.5871C193.69 12.3702 193.261 13.0498 192.71 13.6273C192.159 14.2062 191.497 14.6612 190.72 14.9923C189.945 15.3233 189.097 15.4896 188.176 15.4896C187.58 15.4896 187.043 15.4298 186.565 15.3116C186.087 15.1921 185.667 15.0404 185.307 14.8523C184.946 14.6656 184.638 14.4658 184.386 14.2529C184.134 14.04 183.926 13.8314 183.764 13.6273H183.683V20.9991H180.435V2.93924H180.434ZM191.208 9.06127C191.208 8.58586 191.122 8.12649 190.951 7.68462C190.779 7.24274 190.527 6.85191 190.192 6.51067C189.858 6.17088 189.457 5.89817 188.988 5.69401C188.52 5.48984 187.987 5.38776 187.391 5.38776C186.796 5.38776 186.263 5.48984 185.794 5.69401C185.325 5.89817 184.923 6.17088 184.59 6.51067C184.256 6.85191 184.004 7.24274 183.832 7.68462C183.66 8.12649 183.575 8.58586 183.575 9.06127C183.575 9.53669 183.66 9.99752 183.832 10.4379C184.004 10.8813 184.256 11.2721 184.59 11.6119C184.925 11.9517 185.325 12.2244 185.794 12.4285C186.263 12.6327 186.796 12.7348 187.391 12.7348C187.987 12.7348 188.52 12.6327 188.988 12.4285C189.457 12.2244 189.858 11.9531 190.192 11.6119C190.527 11.2721 190.779 10.8813 190.951 10.4379C191.122 9.99606 191.208 9.53669 191.208 9.06127Z" fill="white"/>
      <path d="M173.848 9.06146L178.803 15.1835H174.852L171.868 11.4998L168.892 15.1835H164.94L177.227 0.000896454H181.178L173.848 9.06146Z" fill="white"/>
      <path d="M168.892 2.93972H164.94L168.939 7.88635L170.912 5.4422L168.892 2.93972Z" fill="white"/>
      <path d="M154.527 17.4769C154.527 17.4302 154.568 17.3879 154.622 17.3879H156.045C156.643 17.3879 157.132 17.8385 157.132 18.3971C157.132 18.8287 156.829 19.1802 156.395 19.345L157.078 20.5364C157.113 20.5977 157.078 20.672 156.993 20.672H156.47C156.425 20.672 156.4 20.6487 156.389 20.6297L155.727 19.3858H155.174V20.5831C155.174 20.6297 155.129 20.672 155.08 20.672H154.622C154.568 20.672 154.527 20.6297 154.527 20.5831V17.4769ZM155.991 18.8841C156.256 18.8841 156.485 18.6683 156.485 18.4058C156.485 18.1564 156.256 17.9508 155.991 17.9508H155.18V18.8841H155.991Z" fill="white"/>
      <path d="M159.231 17.4769C159.231 17.4302 159.271 17.3879 159.325 17.3879H161.353C161.407 17.3879 161.447 17.4302 161.447 17.4769V17.8618C161.447 17.9085 161.407 17.9508 161.353 17.9508H159.878V18.7208H161.108C161.158 18.7208 161.203 18.7631 161.203 18.8098V19.1991C161.203 19.2502 161.158 19.2881 161.108 19.2881H159.878V20.1091H161.353C161.407 20.1091 161.447 20.1514 161.447 20.1981V20.5831C161.447 20.6297 161.407 20.672 161.353 20.672H159.325C159.271 20.672 159.231 20.6297 159.231 20.5831V17.4769Z" fill="white"/>
      <path d="M162.807 20.5512L164.356 17.3925C164.371 17.3648 164.396 17.3415 164.441 17.3415H164.491C164.54 17.3415 164.56 17.3648 164.576 17.3925L166.109 20.5512C166.139 20.6125 166.1 20.6737 166.024 20.6737H165.591C165.517 20.6737 165.481 20.646 165.447 20.5804L165.202 20.0729H163.714L163.469 20.5804C163.449 20.6271 163.404 20.6737 163.325 20.6737H162.892C162.818 20.6737 162.777 20.6125 162.807 20.5512ZM164.958 19.5523L164.46 18.5198H164.444L163.957 19.5523H164.958Z" fill="white"/>
      <path d="M167.559 17.4769C167.559 17.4302 167.599 17.3879 167.653 17.3879H168.111C168.161 17.3879 168.205 17.4302 168.205 17.4769V20.1091H169.476C169.53 20.1091 169.57 20.1514 169.57 20.1981V20.5831C169.57 20.6297 169.53 20.672 169.476 20.672H167.653C167.599 20.672 167.559 20.6297 167.559 20.5831V17.4769Z" fill="white"/>
      <path d="M171.735 17.9508H170.973C170.918 17.9508 170.879 17.9085 170.879 17.8618V17.4769C170.879 17.4302 170.919 17.3879 170.973 17.3879H173.149C173.203 17.3879 173.244 17.4302 173.244 17.4769V17.8618C173.244 17.9085 173.203 17.9508 173.149 17.9508H172.388V20.5831C172.388 20.6297 172.343 20.672 172.293 20.672H171.831C171.781 20.672 171.736 20.6297 171.736 20.5831V17.9508H171.735Z" fill="white"/>
      <path d="M175.988 19.0912L174.852 17.5235C174.812 17.4623 174.852 17.3879 174.931 17.3879H175.438C175.479 17.3879 175.503 17.4112 175.519 17.4302L176.316 18.5093L177.113 17.4302C177.128 17.4112 177.153 17.3879 177.193 17.3879H177.705C177.786 17.3879 177.825 17.4637 177.786 17.5235L176.636 19.0868V20.5845C176.636 20.6312 176.591 20.6735 176.542 20.6735H176.084C176.029 20.6735 175.989 20.6312 175.989 20.5845V19.0927L175.988 19.0912Z" fill="white"/>
    </svg>
  );
};

export default ZoocasaUsaIcon;
